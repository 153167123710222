@import '../variables.css';
.footer {
    background-color: var(--footer-background-color);
    padding: 20px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .linksRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 60%;
    flex-wrap: wrap;
  }

  .footerLink {
    text-decoration: none;
    color: var(--footer-link-color);
    padding: 5px 10px;
    white-space: nowrap;
    font-weight: 550;
    font-size: 1.1em;
  }

  .footerLink:hover {
    text-decoration: none;
  }

  .socialIconsRow {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    overflow: auto;
  }

  .socialIcon {
    text-decoration: none;
    margin: 0 10px;
  }

  .socialIcon img {
    width: 30px;
    height: auto;
  }

  .socialIcon:hover {
    opacity: 0.8;
  }
  .footerCopyright{
    color: var(--footer-copy-color);
  }
  @media  (max-width: 900px) {
    .linksRow {
     flex-direction: column;
    }
  
  }
  