:root {
     /*general*/
    --background-color: #fff;
    --header-color: #32CF8B;
    --text-light-color: #fff;
    --text-dark-color: #00145d;
    --font-family:  "Roboto", sans-serif;
     /*buttons*/
    --button-primary-text-color: #fff;
    --button-secondary-text-color: #fff;
    --button-primary-background-color: #b2cf31;
    --button-secondary-background-color: #23631b;
    --button-primary-hover-background-color: #e5ff72;
    --button-secondary-hover-background-color: rgb(206, 238, 65);
    --button-primary-hover-text-color: #fff;
    --button-secondary-hover-text-color: #fff;
    --button-font-weight: 550;
    --button-border-radius: 5px;
     /*placeholder*/
     --placeholder-color: #999;
     /*product badge*/
     --badge-background-color: #0d908d;
     --badge-text-color: #fff;
     --badge-border-radius: 3px;
     --badge-font-weight: 550;
     /*footer*/
     --footer-background-color: #0ec10b;
     --footer-link-color: #ffffff;
     --footer-copy-color: #fcffde;
     /*cookies*/
     --cookies-background-color: #32CF8B;
     --cookies-text-color: #23631b;
     --cookies-link-color: rgb(120, 135, 53);
     --cookies-copy-color: #fcffde;
     --cookies-link-font-weight: 550;
  }
  