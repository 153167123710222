@import '../variables.css';

.cookieBar {
  background-color: var(--cookies-background-color);
  padding: 10px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 80vh;
  left: 10px;
  width: 50%;
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 10px;
}

.cookieImage {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 10px;
}

.cookieBar p {
  margin: 0;
  flex: 1;
  font-size: 14px;
  color: var(--cookies-text-color);
}

.cookieBar a {
  color: var(--cookies-link-color);
  text-decoration: none;
  font-weight: var(--cookies-link-font-weight);
}

.cookieBar a:hover {
  text-decoration: underline;
}

.confirmButton {
  background-color: var(--button-secondary-background-color);
  color: var(--button-secondary-text-color);
  font-weight: var(--button-font-weight);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.confirmButton:hover {
  background-color: var(--button-secondary-hover-background-color);
  color: var(--button-secondary-hover-text-color);
}

@media (max-width: 768px) {
  .cookieBar {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 5%;
    top: 50vh;
  left: 10px;
  width: 50%;
  }

  .cookieBar p {
    margin-bottom: 10px;
    width: 100%;
  }

  .confirmButton {
    width: 100%;
    text-align: center;
  }
}
