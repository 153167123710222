@import '../variables.css';

.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--text-light-color); /* White text color for better contrast */
  text-align: center;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Below the content */
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Transparent black layer */
  z-index: 1; /* Below the content */
}

.content {
  position: relative;
  z-index: 2; /* Above the overlay */
  max-width: 80%;
  margin: 0 auto;
}

.slogan {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.orderButton {
  display: inline-block;
  padding: 15px 25px;
  font-size: 1.5rem;
  color: var(--button-primary-text-color);
  background-color: var(--button-primary-background-color);
  font-weight: var(--button-font-weight);
  border: none;
  border-radius: var(--button-border-radius);
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.orderButton:hover {
  background-color: var(--button-primary-hover-background-color);
  color: var(--button-primary-hover-text-color);
}
.button1 {
  background-color: #ff6347; /* Tomato */
  margin: 10px;
}

.button2 {
  background-color: #4682b4; /* SteelBlue */
  margin: 10px;
}

.button3 {
  background-color: #32cd32; /* LimeGreen */
  margin: 10px;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .slogan {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .orderButton {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

.orderButton:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .buttons {
    flex-direction: column;
    margin: 0 auto;
  }

  .orderButton {
    width: 100%;
  }
  .buttons{
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .button1 {
    background-color: #ff6347; /* Tomato */
    margin: 10px;
    width: 70%;
  }
  
  .button2 {
    background-color: #4682b4; /* SteelBlue */
    margin: 10px;
    width: 70%;
  }
  
  .button3 {
    background-color: #32cd32; /* LimeGreen */
    margin: 10px;
    width: 70%;
  }
  
}